import React, { useState } from "react";
import { Box, Divider, Grid, Typography } from "@mui/material";
import LineChart from "./LineChart";
import { motion } from "framer-motion";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import ApplicantsBox from "./ApplicantsBox";
import AcceptedBox from "./AcceptedBox";
import InterestsBox from "./InterestsBox";
import { applicants, acceptedUsers } from "./data";

const Dashboard = () => {
  let numApplicants = applicants.length;
  let numActiveUsers = acceptedUsers.length;

  const [hasCounted, setHasCounted] = useState(false);
  return (
    <>
      <Box sx={{ border: "1.5px solid #e0e0e0", padding: "20px 8px", borderTopRightRadius: "8px", borderTopLeftRadius: "8px" }}>
        <Grid container spacing={4} justifyContent="center">
          <Grid item xs={4} sm={4} md={4}>
            <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
              <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                <Typography variant="body1" sx={{ color: "gray" }}>
                  Applicants
                </Typography>
                <Typography variant="h4" sx={{ fontWeight: "bold", lineHeight: "95%" }}>
                  <VisibilitySensor active={!hasCounted}>{({ isVisible }) => <CountUp end={isVisible ? numApplicants : 0} duration={5} />}</VisibilitySensor>
                </Typography>
              </Box>
            </motion.div>
          </Grid>

          <Grid item xs={4} sm={4} md={4}>
            <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
              <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                <Typography variant="body1" sx={{ color: "gray" }}>
                  Active Users
                </Typography>
                <Typography variant="h4" sx={{ fontWeight: "bold", lineHeight: "95%" }}>
                  <VisibilitySensor active={!hasCounted}>{({ isVisible }) => <CountUp end={isVisible ? numActiveUsers : 0} duration={5} />}</VisibilitySensor>
                </Typography>
              </Box>
            </motion.div>
          </Grid>
          <Grid item xs={4} sm={4} md={4}>
            <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
              <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                <Typography variant="body1" sx={{ color: "gray" }}>
                  Total Posts
                </Typography>
                <Typography variant="h4" sx={{ fontWeight: "bold", lineHeight: "95%" }}>
                  <VisibilitySensor
                    active={!hasCounted}
                    onChange={(isVisible) => {
                      if (isVisible) {
                        setHasCounted(true);
                      }
                    }}>
                    {({ isVisible }) => <CountUp end={isVisible ? 51 : 0} duration={5} />}
                  </VisibilitySensor>
                </Typography>
              </Box>
            </motion.div>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          borderLeft: "1.5px solid #e0e0e0",
          borderRight: "1.5px solid #e0e0e0",
          borderBottom: "1.5px solid #e0e0e0",
          padding: "8px 8px",
        }}>
        <Box sx={{ display: "flex", flexDirection: "column",  }}>
          <LineChart applicants={applicants} acceptedUsers={acceptedUsers} />
        </Box>
      </Box>

      <Box
        sx={{
          borderLeft: "1.5px solid #e0e0e0",
          borderRight: "1.5px solid #e0e0e0",
          borderBottom: "1.5px solid #e0e0e0",
          borderBottomLeftRadius: "8px",
          borderBottomRightRadius: "8px",
        }}>
        {/* User Data */}

        <Box sx={{ display: "flex", flexWrap: "wrap" }}>
          <Box sx={{ flex: "1 1 auto" }}>
            {" "}
            {/* Adjusted */}
            <ApplicantsBox applicants={applicants} />
          </Box>

          <Divider orientation="vertical" flexItem />

          <Box sx={{ flex: "1 1 auto" }}>
            {" "}
            {/* Adjusted */}
            <AcceptedBox acceptedUsers={acceptedUsers} />
          </Box>

          <Divider orientation="vertical" flexItem />

          <Box sx={{ flex: "2 1 auto" }}>
            {" "}
            {/* Adjusted */}
            <InterestsBox applicants={applicants} acceptedUsers={acceptedUsers} />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Dashboard;