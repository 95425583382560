import React, { useState } from "react";
import { signIn } from "../utils/firebase"; // Assuming this is where your Firebase auth functions are
import { useNavigate } from "react-router-dom";
import { Formik, Field, Form } from "formik";
import { TextField, Typography, Alert } from "@mui/material";
import Button from "@mui/material/Button";

const AdminLogin = () => {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const handleLogin = async (values) => {
    try {
        console.log("values", values);
      const response = await signIn(values.email, values.password);
        console.log("user", response.user);
        console.log("user.uid", response.user.uid);
      if (response.user && response.user.uid === "S0DD49pFNpdeaFA2JpT3m42dDig1") { // Replace "admin_uid" with the actual admin UID
        setErrorMessage(""); // Clear the error message
        setSuccessMessage("Admin login successful"); // Set the success message
        setTimeout(() => {
          navigate("/dashboard"); // Navigate to the admin dashboard
        }, 1000);
      } else {
        setErrorMessage("You are not authorized to access this page");
      }
    } catch (error) {
      setErrorMessage("Invalid email or password"); // Set the error message
      console.error(error.message);
    }
  };

  return (
    <>
      <Formik initialValues={{ email: "", password: "" }} onSubmit={handleLogin}>
        {({ isSubmitting }) => (
          <Form
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 12,
              maxWidth: "600px",
              margin: "auto",
            }}>
            <Typography variant="h4" sx={{ textAlign: "center" }}>
              Admin Login
            </Typography>
            {successMessage && (
              <Alert severity="success" variant="outlined" sx={{ textAlign: "center" }}>
                {successMessage}
              </Alert>
            )}
            {errorMessage && (
              <Alert severity="error" variant="outlined" sx={{ textAlign: "center" }}>
                {errorMessage}
              </Alert>
            )}
            <Field name="email" as={TextField} label="Email" required />
            <Field name="password" as={TextField} label="Password" type="password" required />
            <Button variant="contained" type="submit" disabled={isSubmitting}>
              Login
            </Button>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default AdminLogin;
