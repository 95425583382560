import React from "react";
import { motion } from "framer-motion";
import { Typography, Grid, Box } from "@mui/material";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import HowToRegIcon from "@mui/icons-material/HowToReg";

const items = [
  {
    id: "1",
    title: "Get Paid to Post",
    subtitle: "No Follower Requirements",
    description:
      "Browse + join campaigns, post photos/videos of your favorite products, get paid. There is no follower requirement to sign-up. Just link your personal social media accounts that are active for over 4 years.",
    icon: <HowToRegIcon fontSize="large" color="primary" />,
  },
  {
    id: "2",
    title: "Democratizing Influence",
    subtitle: "Next-gen technology",
    description: "Recruit, track, and manage thousands of your customers for marketing support on social media. We enable brands to market through their customers at scale.",
    icon: <SettingsApplicationsIcon fontSize="large" color="primary" />,
  },
  {
    id: "3",
    title: "Empowering Individuals",
    subtitle: "Support favorite companies",
    description:
      "We offer all social media users an opportunity to support their favorite companies and products in a number of ways in exhange for compensation. Join the waitlist today!",
    icon: <PersonAddAlt1Icon fontSize="large" color="primary" />,
  },
];

const ReachSection = () => {
  return (
    <Grid container spacing={4} justifyContent="center">
      {items.map((item) => (
        <Grid item key={item.id} xs={12} sm={6} md={4}>
          <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
            <Box elevation={3} sx={{ backgroundColor: "#cde5f9", padding: "40px 20px", borderRadius: "8px" }}>
              <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "white",
                    width: "fit-content",
                    padding: ".5rem",
                    height: "fit-content",
                    borderRadius: "100%",
                    mb: "1rem",
                  }}>
                  {item.icon}
                </Box>
                <Typography variant="h5" component="h2" color="primary" align="center">
                  {item.title}
                </Typography>

                <Typography variant="body1" sx={{ color: "gray" }} align="center">
                  {item.description}
                </Typography>
              </Box>
            </Box>
          </motion.div>
        </Grid>
      ))}
    </Grid>
  );
};

export default ReachSection;
