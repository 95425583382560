const applicants = [
    {
      userId: "1",
      appliedOn: "2024-06-10",
      userProfile: {
        birthday: "1985-03-22",
        email: "taylorjones@gmail.com",
        firstName: "Taylor",
        lastName: "Jones",
        phoneNumber: "5718399812",
        city: "New York",
        state: "New York",
        interests: ["Tech", "Travel", "Art"],
        description: "I am a tech enthusiast who loves to travel and explore new places. I am also an artist who loves to paint and draw.",
        socials: {
          instagram: { followers: 95320, username: "taylor63" },
          tiktok: { followers: 77128, username: "taylor47" },
          twitter: { followers: 45689, username: "taylor95" },
          linkedin: { followers: 62332, username: "taylor98" },
        },
        budgets: {
          campaignA: 5,
          campaignB: 50,
          campaignC: 150,
        },
      },
    },
    {
      userId: "2",
      appliedOn: "2024-06-10",
      userProfile: {
        birthday: "1990-07-15",
        email: "alexsmith@gmail.com",
        firstName: "Alex",
        lastName: "Smith",
        phoneNumber: "2347891234",
        city: "Los Angeles",
        state: "California",
        interests: ["Music", "Gaming"],
        description: "Music lover and avid gamer. I enjoy attending concerts and playing the latest video games.",
        socials: {
          instagram: { followers: 10432, username: "alex_smith_music" },
          tiktok: { followers: 23145, username: "alex_gamer" },
          twitter: { followers: 7891, username: "alex_tweets" },
          linkedin: { followers: 3214, username: "alex_professional" },
        },
        budgets: {
          campaignA: 10,
          campaignB: 55,
          campaignC: 200,
        },
      },
    },
    {
      userId: "3",
      appliedOn: "2024-06-10",
      userProfile: {
        birthday: "1995-02-10",
        email: "jordanbrown@gmail.com",
        firstName: "Jordan",
        lastName: "Brown",
        phoneNumber: "3129876543",
        city: "Chicago",
        state: "Illinois",
        interests: ["Photography", "Blogging", "Fitness"],
        description: "I am a fitness enthusiast who loves capturing moments through photography and sharing my journey on my blog.",
        socials: {
          instagram: { followers: 65234, username: "jordan_photo" },
          tiktok: { followers: 47123, username: "jordan_fit" },
          twitter: { followers: 32890, username: "jordan_blogs" },
          linkedin: { followers: 14325, username: "jordan_brown" },
        },
        budgets: {
          campaignA: 20,
          campaignB: 60,
          campaignC: 300,
        },
      },
    },
    {
      userId: "4",
      appliedOn: "2024-06-15",
      userProfile: {
        birthday: "1988-11-05",
        email: "morganlee@gmail.com",
        firstName: "Morgan",
        lastName: "Lee",
        phoneNumber: "2156783456",
        city: "Philadelphia",
        state: "Pennsylvania",
        interests: ["Cooking", "Travel", "Tech"],
        description: "Passionate about cooking and traveling the world. I also keep up with the latest in tech.",
        socials: {
          instagram: { followers: 47320, username: "morgan_cooks" },
          tiktok: { followers: 32145, username: "morgan_travels" },
          twitter: { followers: 28901, username: "morgan_tech" },
          linkedin: { followers: 16543, username: "morgan_lee" },
        },
        budgets: {
          campaignA: 15,
          campaignB: 70,
          campaignC: 400,
        },
      },
    },
    {
      userId: "5",
      appliedOn: "2024-06-23",
      userProfile: {
        birthday: "1992-12-12",
        email: "caseydavis@gmail.com",
        firstName: "Casey",
        lastName: "Davis",
        phoneNumber: "6462345678",
        city: "New York",
        state: "New York",
        interests: ["Fashion", "Travel"],
        description: "Fashion enthusiast who loves to travel and explore new styles around the world.",
        socials: {
          instagram: { followers: 89321, username: "casey_fashion" },
          tiktok: { followers: 67890, username: "casey_travels" },
          twitter: { followers: 51234, username: "casey_style" },
          linkedin: { followers: 34123, username: "casey_davis" },
        },
        budgets: {
          campaignA: 8,
          campaignB: 65,
          campaignC: 250,
        },
      },
    },
    {
      userId: "6",
      appliedOn: "2024-06-25",
      userProfile: {
        birthday: "1984-08-20",
        email: "jamieclark@gmail.com",
        firstName: "Jamie",
        lastName: "Clark",
        phoneNumber: "3056782345",
        city: "Miami",
        state: "Florida",
        interests: ["Surfing", "Fitness", "Tech"],
        description: "Tech geek who loves surfing and staying fit.",
        socials: {
          instagram: { followers: 45021, username: "jamie_surfs" },
          tiktok: { followers: 32987, username: "jamie_fitness" },
          twitter: { followers: 21034, username: "jamie_tech" },
          linkedin: { followers: 14321, username: "jamie_clark" },
        },
        budgets: {
          campaignA: 12,
          campaignB: 72,
          campaignC: 500,
        },
      },
    },
    {
      userId: "7",
      appliedOn: "2024-06-29",
      userProfile: {
        birthday: "1997-01-29",
        email: "rileysmith@gmail.com",
        firstName: "Riley",
        lastName: "Smith",
        phoneNumber: "7021234567",
        city: "Las Vegas",
        state: "Nevada",
        interests: ["Gaming", "Travel"],
        description: "Gaming aficionado who enjoys traveling and experiencing new cultures.",
        socials: {
          instagram: { followers: 65214, username: "riley_games" },
          tiktok: { followers: 49123, username: "riley_travels" },
          twitter: { followers: 32109, username: "riley_adventures" },
          linkedin: { followers: 15320, username: "riley_smith" },
        },
        budgets: {
          campaignA: 10,
          campaignB: 68,
          campaignC: 350,
        },
      },
    },
    {
      userId: "8",
      appliedOn: "2024-07-03",
      userProfile: {
        birthday: "1989-04-14",
        email: "alexisjones@gmail.com",
        firstName: "Alexis",
        lastName: "Jones",
        phoneNumber: "3034567890",
        city: "Denver",
        state: "Colorado",
        interests: ["Hiking", "Photography", "Tech"],
        description: "Outdoor enthusiast with a passion for photography and the latest in tech.",
        socials: {
          instagram: { followers: 87345, username: "alexis_hikes" },
          tiktok: { followers: 65432, username: "alexis_photos" },
          twitter: { followers: 41234, username: "alexis_tech" },
          linkedin: { followers: 23145, username: "alexis_jones" },
        },
        budgets: {
          campaignA: 20,
          campaignB: 75,
          campaignC: 450,
        },
      },
    },
    {
      userId: "9",
      appliedOn: "2024-07-05",
      userProfile: {
        birthday: "1993-10-08",
        email: "drewmartin@gmail.com",
        firstName: "Drew",
        lastName: "Martin",
        phoneNumber: "6172345678",
        city: "Boston",
        state: "Massachusetts",
        interests: ["Reading", "Writing"],
        description: "Avid reader and writer who loves to share thoughts and stories.",
        socials: {
          instagram: { followers: 31245, username: "drew_reads" },
          tiktok: { followers: 21345, username: "drew_writes" },
          twitter: { followers: 13245, username: "drew_tweets" },
          linkedin: { followers: 9423, username: "drew_martin" },
        },
        budgets: {
          campaignA: 15,
          campaignB: 55,
          campaignC: 300,
        },
      },
    },
    {
      userId: "10",
      appliedOn: "2024-06-10",
      userProfile: {
        birthday: "1986-09-30",
        email: "kylewalker@gmail.com",
        firstName: "Kyle",
        lastName: "Walker",
        phoneNumber: "4083456789",
        city: "San Francisco",
        state: "California",
        interests: ["Tech", "Gaming", "Music"],
        description: "Tech enthusiast, gamer, and music lover who enjoys combining these interests.",
        socials: {
          instagram: { followers: 93456, username: "kyle_tech" },
          tiktok: { followers: 75432, username: "kyle_gamer" },
          twitter: { followers: 54321, username: "kyle_music" },
          linkedin: { followers: 31234, username: "kyle_walker" },
        },
        budgets: {
          campaignA: 18,
          campaignB: 65,
          campaignC: 400,
        },
      },
    },
    {
      userId: "11",
      appliedOn: "2024-06-15",
      userProfile: {
        birthday: "1991-06-21",
        email: "chriswilliams@gmail.com",
        firstName: "Chris",
        lastName: "Williams",
        phoneNumber: "5129876543",
        city: "Austin",
        state: "Texas",
        interests: ["Food", "Travel", "Tech"],
        description: "Foodie who loves traveling and keeping up with the latest in tech.",
        socials: {
          instagram: { followers: 82345, username: "chris_food" },
          tiktok: { followers: 62345, username: "chris_travel" },
          twitter: { followers: 41234, username: "chris_tech" },
          linkedin: { followers: 22134, username: "chris_williams" },
        },
        budgets: {
          campaignA: 14,
          campaignB: 62,
          campaignC: 370,
        },
      },
    },
    {
      userId: "12",
      appliedOn: "2024-07-05",
      userProfile: {
        birthday: "1994-05-03",
        email: "samuelmiller@gmail.com",
        firstName: "Samuel",
        lastName: "Miller",
        phoneNumber: "8182345678",
        city: "Los Angeles",
        state: "California",
        interests: ["Fitness", "Tech"],
        description: "Fitness junkie who loves staying updated with the latest technology trends.",
        socials: {
          instagram: { followers: 53421, username: "samuel_fit" },
          tiktok: { followers: 43210, username: "samuel_tech" },
          twitter: { followers: 32109, username: "samuel_updates" },
          linkedin: { followers: 12345, username: "samuel_miller" },
        },
        budgets: {
          campaignA: 13,
          campaignB: 57,
          campaignC: 310,
        },
      },
    },
    {
      userId: "13",
      appliedOn: "2024-06-25",
      userProfile: {
        birthday: "1987-02-14",
        email: "kimberlymartin@gmail.com",
        firstName: "Kimberly",
        lastName: "Martin",
        phoneNumber: "9174567890",
        city: "New York",
        state: "New York",
        interests: ["Tech", "Art", "Travel"],
        description: "Art lover with a passion for technology and exploring new places.",
        socials: {
          instagram: { followers: 76543, username: "kimberly_art" },
          tiktok: { followers: 65432, username: "kimberly_travel" },
          twitter: { followers: 54321, username: "kimberly_tech" },
          linkedin: { followers: 43210, username: "kimberly_martin" },
        },
        budgets: {
          campaignA: 19,
          campaignB: 69,
          campaignC: 390,
        },
      },
    },
    {
      userId: "14",
      appliedOn: "2024-07-01",
      userProfile: {
        birthday: "1990-11-11",
        email: "brianroberts@gmail.com",
        firstName: "Brian",
        lastName: "Roberts",
        phoneNumber: "7132345678",
        city: "Houston",
        state: "Texas",
        interests: ["Tech", "Music"],
        description: "Tech enthusiast and music lover. Enjoys staying on top of the latest tech trends and listening to various music genres.",
        socials: {
          instagram: { followers: 65432, username: "brian_tech" },
          tiktok: { followers: 54321, username: "brian_music" },
          twitter: { followers: 43210, username: "brian_updates" },
          linkedin: { followers: 32109, username: "brian_roberts" },
        },
        budgets: {
          campaignA: 11,
          campaignB: 75,
          campaignC: 450,
        },
      },
    },
    {
      userId: "15",
      appliedOn: "2024-07-05",
      userProfile: {
        birthday: "1993-08-17",
        email: "nicolerichardson@gmail.com",
        firstName: "Nicole",
        lastName: "Richardson",
        phoneNumber: "2023456789",
        city: "Washington",
        state: "DC",
        interests: ["Politics", "Tech", "Fitness"],
        description: "Political enthusiast with a knack for tech and fitness.",
        socials: {
          instagram: { followers: 82345, username: "nicole_politics" },
          tiktok: { followers: 65432, username: "nicole_fit" },
          twitter: { followers: 43210, username: "nicole_tech" },
          linkedin: { followers: 32109, username: "nicole_richardson" },
        },
        budgets: {
          campaignA: 12,
          campaignB: 77,
          campaignC: 500,
        },
      },
    },
    {
      userId: "16",
      appliedOn: "2024-07-08",
      userProfile: {
        birthday: "1985-04-01",
        email: "angeladavis@gmail.com",
        firstName: "Angela",
        lastName: "Davis",
        phoneNumber: "6021234567",
        city: "Phoenix",
        state: "Arizona",
        interests: ["Reading", "Art"],
        description: "Art lover and avid reader who enjoys diving into new books and creating art.",
        socials: {
          instagram: { followers: 54321, username: "angela_reads" },
          tiktok: { followers: 43210, username: "angela_art" },
          twitter: { followers: 32109, username: "angela_updates" },
          linkedin: { followers: 21098, username: "angela_davis" },
        },
        budgets: {
          campaignA: 8,
          campaignB: 52,
          campaignC: 200,
        },
      },
    },
    {
      userId: "17",
      appliedOn: "2024-06-15",
      userProfile: {
        birthday: "1989-09-19",
        email: "ethanwhite@gmail.com",
        firstName: "Ethan",
        lastName: "White",
        phoneNumber: "9192345678",
        city: "Raleigh",
        state: "North Carolina",
        interests: ["Gaming", "Travel", "Music"],
        description: "Gamer and music lover who enjoys traveling to new places and experiencing different cultures.",
        socials: {
          instagram: { followers: 62345, username: "ethan_games" },
          tiktok: { followers: 51234, username: "ethan_travels" },
          twitter: { followers: 40123, username: "ethan_music" },
          linkedin: { followers: 29012, username: "ethan_white" },
        },
        budgets: {
          campaignA: 16,
          campaignB: 66,
          campaignC: 370,
        },
      },
    },
    {
      userId: "18",
      appliedOn: "2024-06-20",
      userProfile: {
        birthday: "1992-11-25",
        email: "abbywilson@gmail.com",
        firstName: "Abby",
        lastName: "Wilson",
        phoneNumber: "5023456789",
        city: "Louisville",
        state: "Kentucky",
        interests: ["Cooking", "Fitness", "Tech"],
        description: "Fitness enthusiast and tech lover who enjoys cooking and trying out new recipes.",
        socials: {
          instagram: { followers: 73421, username: "abby_cooks" },
          tiktok: { followers: 61234, username: "abby_fit" },
          twitter: { followers: 50123, username: "abby_tech" },
          linkedin: { followers: 39012, username: "abby_wilson" },
        },
        budgets: {
          campaignA: 9,
          campaignB: 63,
          campaignC: 450,
        },
      },
    },
    {
      userId: "19",
      appliedOn: "2024-06-25",
      userProfile: {
        birthday: "1987-06-15",
        email: "michaelthomas@gmail.com",
        firstName: "Michael",
        lastName: "Thomas",
        phoneNumber: "3145678901",
        city: "St. Louis",
        state: "Missouri",
        interests: ["Tech", "Travel", "Photography"],
        description: "Tech enthusiast who loves traveling and capturing moments through photography.",
        socials: {
          instagram: { followers: 83456, username: "michael_tech" },
          tiktok: { followers: 72345, username: "michael_travels" },
          twitter: { followers: 61234, username: "michael_photo" },
          linkedin: { followers: 50123, username: "michael_thomas" },
        },
        budgets: {
          campaignA: 20,
          campaignB: 70,
          campaignC: 550,
        },
      },
    },
    {
      userId: "20",
      appliedOn: "2024-06-30",
      userProfile: {
        birthday: "1995-12-09",
        email: "emilyjohnson@gmail.com",
        firstName: "Emily",
        lastName: "Johnson",
        phoneNumber: "8173456789",
        city: "Dallas",
        state: "Texas",
        interests: ["Fashion", "Tech", "Travel"],
        description: "Fashion enthusiast who loves tech and traveling to explore new styles.",
        socials: {
          instagram: { followers: 92345, username: "emily_fashion" },
          tiktok: { followers: 81234, username: "emily_tech" },
          twitter: { followers: 70123, username: "emily_travels" },
          linkedin: { followers: 60123, username: "emily_johnson" },
        },
        budgets: {
          campaignA: 17,
          campaignB: 77,
          campaignC: 600,
        },
      },
    },
  ];
  
  const acceptedUsers = [
    {
      userId: "21",
      acceptedOn: "2024-06-15",
      userProfile: {
        birthday: "1990-05-14",
        email: "johnsmith@gmail.com",
        firstName: "John",
        lastName: "Smith",
        phoneNumber: "9172345678",
        city: "New York",
        state: "New York",
        interests: ["Music", "Cooking"],
        description: "Music lover and aspiring chef who enjoys creating new dishes.",
        socials: {
          instagram: { followers: 45321, username: "john_music" },
          tiktok: { followers: 34221, username: "john_cooks" },
          twitter: { followers: 22143, username: "john_chef" },
          linkedin: { followers: 14321, username: "john_smith" },
        },
        budgets: {
          campaignA: 20,
          campaignB: 50,
          campaignC: 200,
        },
      },
    },
    {
      userId: "22",
      acceptedOn: "2024-06-29",
      userProfile: {
        birthday: "1988-11-23",
        email: "sarahwilliams@gmail.com",
        firstName: "Sarah",
        lastName: "Williams",
        phoneNumber: "7273456789",
        city: "Los Angeles",
        state: "California",
        interests: ["Fitness", "Wellness"],
        description: "Fitness trainer and wellness advocate promoting a healthy lifestyle.",
        socials: {
          instagram: { followers: 62345, username: "sarah_fitness" },
          tiktok: { followers: 51234, username: "sarah_wellness" },
          twitter: { followers: 40123, username: "sarah_health" },
          linkedin: { followers: 30123, username: "sarah_williams" },
        },
        budgets: {
          campaignA: 30,
          campaignB: 70,
          campaignC: 400,
        },
      },
    },
    {
      userId: "23",
      acceptedOn: "2024-06-29",
      userProfile: {
        birthday: "1992-02-17",
        email: "michaelbrown@gmail.com",
        firstName: "Michael",
        lastName: "Brown",
        phoneNumber: "3374567890",
        city: "Houston",
        state: "Texas",
        interests: ["Gaming", "Tech"],
        description: "Gamer and tech enthusiast who reviews the latest gadgets.",
        socials: {
          instagram: { followers: 55345, username: "michael_gaming" },
          tiktok: { followers: 45234, username: "michael_tech" },
          twitter: { followers: 35123, username: "michael_gadgets" },
          linkedin: { followers: 25123, username: "michael_brown" },
        },
        budgets: {
          campaignA: 25,
          campaignB: 65,
          campaignC: 350,
        },
      },
    },
    {
      userId: "24",
      acceptedOn: "2024-06-29",
      userProfile: {
        birthday: "1994-06-11",
        email: "jessicadavis@gmail.com",
        firstName: "Jessica",
        lastName: "Davis",
        phoneNumber: "9375678901",
        city: "Chicago",
        state: "Illinois",
        interests: ["Photography", "Travel"],
        description: "Photographer and traveler capturing moments around the world.",
        socials: {
          instagram: { followers: 67345, username: "jessica_photos" },
          tiktok: { followers: 56234, username: "jessica_travels" },
          twitter: { followers: 45123, username: "jessica_moments" },
          linkedin: { followers: 35123, username: "jessica_davis" },
        },
        budgets: {
          campaignA: 35,
          campaignB: 85,
          campaignC: 500,
        },
      },
    },
    {
      userId: "25",
      acceptedOn: "2024-07-01",
      userProfile: {
        birthday: "1985-09-29",
        email: "davidharris@gmail.com",
        firstName: "David",
        lastName: "Harris",
        phoneNumber: "6476789012",
        city: "San Francisco",
        state: "California",
        interests: ["Finance", "Investing"],
        description: "Financial advisor helping people achieve their financial goals.",
        socials: {
          instagram: { followers: 33345, username: "david_finance" },
          tiktok: { followers: 22234, username: "david_investing" },
          twitter: { followers: 11123, username: "david_money" },
          linkedin: { followers: 7123, username: "david_harris" },
        },
        budgets: {
          campaignA: 40,
          campaignB: 90,
          campaignC: 550,
        },
      },
    },
    {
      userId: "26",
      acceptedOn: "2024-07-01",
      userProfile: {
        birthday: "1997-03-05",
        email: "ashleymartin@gmail.com",
        firstName: "Ashley",
        lastName: "Martin",
        phoneNumber: "7577890123",
        city: "Miami",
        state: "Florida",
        interests: ["Cooking", "Blogging"],
        description: "Food blogger sharing delicious recipes and cooking tips.",
        socials: {
          instagram: { followers: 78345, username: "ashley_cooks" },
          tiktok: { followers: 67234, username: "ashley_blog" },
          twitter: { followers: 56123, username: "ashley_food" },
          linkedin: { followers: 46123, username: "ashley_martin" },
        },
        budgets: {
          campaignA: 45,
          campaignB: 95,
          campaignC: 600,
        },
      },
    },
    {
      userId: "27",
      acceptedOn: "2024-07-01",
      userProfile: {
        birthday: "1991-07-19",
        email: "chriswilson@gmail.com",
        firstName: "Chris",
        lastName: "Wilson",
        phoneNumber: "8678901234",
        city: "Seattle",
        state: "Washington",
        interests: ["Fitness", "Tech"],
        description: "Fitness coach integrating technology into workout routines.",
        socials: {
          instagram: { followers: 88345, username: "chris_fitness" },
          tiktok: { followers: 77234, username: "chris_tech" },
          twitter: { followers: 66123, username: "chris_workouts" },
          linkedin: { followers: 56123, username: "chris_wilson" },
        },
        budgets: {
          campaignA: 50,
          campaignB: 100,
          campaignC: 650,
        },
      },
    },
    {
      userId: "28",
      acceptedOn: "2024-07-01",
      userProfile: {
        birthday: "1989-11-07",
        email: "lauragarcia@gmail.com",
        firstName: "Laura",
        lastName: "Garcia",
        phoneNumber: "9789012345",
        city: "Austin",
        state: "Texas",
        interests: ["Yoga", "Wellness"],
        description: "Yoga instructor and wellness advocate promoting balance and harmony.",
        socials: {
          instagram: { followers: 48345, username: "laura_yoga" },
          tiktok: { followers: 37234, username: "laura_wellness" },
          twitter: { followers: 26123, username: "laura_balance" },
          linkedin: { followers: 16123, username: "laura_garcia" },
        },
        budgets: {
          campaignA: 55,
          campaignB: 105,
          campaignC: 700,
        },
      },
    },
    {
      userId: "29",
      acceptedOn: "2024-07-01",
      userProfile: {
        birthday: "1993-08-23",
        email: "danielmartinez@gmail.com",
        firstName: "Daniel",
        lastName: "Martinez",
        phoneNumber: "7890123456",
        city: "Denver",
        state: "Colorado",
        interests: ["Hiking", "Photography"],
        description: "Outdoor enthusiast capturing breathtaking landscapes.",
        socials: {
          instagram: { followers: 42345, username: "daniel_hiking" },
          tiktok: { followers: 31234, username: "daniel_photos" },
          twitter: { followers: 20123, username: "daniel_landscapes" },
          linkedin: { followers: 10123, username: "daniel_martinez" },
        },
        budgets: {
          campaignA: 60,
          campaignB: 110,
          campaignC: 750,
        },
      },
    },
    {
      userId: "30",
      acceptedOn: "2024-07-03",
      userProfile: {
        birthday: "1996-04-12",
        email: "meganlee@gmail.com",
        firstName: "Megan",
        lastName: "Lee",
        phoneNumber: "8901234567",
        city: "San Diego",
        state: "California",
        interests: ["Surfing", "Travel"],
        description: "Surfer and travel blogger sharing adventures from around the world.",
        socials: {
          instagram: { followers: 90345, username: "megan_surf" },
          tiktok: { followers: 79234, username: "megan_travel" },
          twitter: { followers: 68123, username: "megan_adventures" },
          linkedin: { followers: 58123, username: "megan_lee" },
        },
        budgets: {
          campaignA: 65,
          campaignB: 115,
          campaignC: 800,
        },
      },
    },
    {
      userId: "31",
      acceptedOn: "2024-07-03",
      userProfile: {
        birthday: "1994-01-25",
        email: "brianwalker@gmail.com",
        firstName: "Brian",
        lastName: "Walker",
        phoneNumber: "9012345678",
        city: "Portland",
        state: "Oregon",
        interests: ["Cycling", "Tech"],
        description: "Cyclist and tech enthusiast exploring new gadgets and rides.",
        socials: {
          instagram: { followers: 50345, username: "brian_cycles" },
          tiktok: { followers: 39234, username: "brian_tech" },
          twitter: { followers: 28123, username: "brian_rides" },
          linkedin: { followers: 18123, username: "brian_walker" },
        },
        budgets: {
          campaignA: 70,
          campaignB: 120,
          campaignC: 850,
        },
      },
    },
    {
      userId: "32",
      acceptedOn: "2024-07-05",
      userProfile: {
        birthday: "1991-10-18",
        email: "katieadams@gmail.com",
        firstName: "Katie",
        lastName: "Adams",
        phoneNumber: "9123456789",
        city: "Atlanta",
        state: "Georgia",
        interests: ["Running", "Fitness"],
        description: "Runner and fitness coach promoting a healthy and active lifestyle.",
        socials: {
          instagram: { followers: 73345, username: "katie_runs" },
          tiktok: { followers: 62234, username: "katie_fitness" },
          twitter: { followers: 51123, username: "katie_active" },
          linkedin: { followers: 41123, username: "katie_adams" },
        },
        budgets: {
          campaignA: 75,
          campaignB: 125,
          campaignC: 900,
        },
      },
    },
    {
      userId: "33",
      acceptedOn: "2024-07-08",
      userProfile: {
        birthday: "1998-08-04",
        email: "nathanielmoore@gmail.com",
        firstName: "Nathaniel",
        lastName: "Moore",
        phoneNumber: "6234567890",
        city: "Phoenix",
        state: "Arizona",
        interests: ["Art", "Design"],
        description: "Artist and designer creating unique pieces of art and design.",
        socials: {
          instagram: { followers: 45345, username: "nathaniel_art" },
          tiktok: { followers: 34234, username: "nathaniel_design" },
          twitter: { followers: 23123, username: "nathaniel_creates" },
          linkedin: { followers: 13123, username: "nathaniel_moore" },
        },
        budgets: {
          campaignA: 80,
          campaignB: 130,
          campaignC: 950,
        },
      },
    },
    {
      userId: "34",
      acceptedOn: "2024-07-08",
      userProfile: {
        birthday: "1987-11-30",
        email: "rebeccahall@gmail.com",
        firstName: "Rebecca",
        lastName: "Hall",
        phoneNumber: "7345678901",
        city: "Charlotte",
        state: "North Carolina",
        interests: ["Gardening", "Cooking"],
        description: "Gardener and cook sharing tips and recipes from the garden.",
        socials: {
          instagram: { followers: 88345, username: "rebecca_garden" },
          tiktok: { followers: 77234, username: "rebecca_cooks" },
          twitter: { followers: 66123, username: "rebecca_tips" },
          linkedin: { followers: 56123, username: "rebecca_hall" },
        },
        budgets: {
          campaignA: 85,
          campaignB: 135,
          campaignC: 1000,
        },
      },
    },
  ];
  
  export { applicants, acceptedUsers };