import React from "react";
import { Box, Typography, IconButton, Grid, Avatar, Chip, Button } from "@mui/material";
import { Instagram, Linkedin, Tiktok, TwitterX, Cake, Calendar, Phone, Envelope, Send } from "react-bootstrap-icons";
import { format } from "date-fns";
import useMediaQuery from "react-responsive";

const AccountSocialCard = ({ user, handleProfileClose }) => {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  function formatNumber(num) {
    if (num < 1000) return num.toString();
    if (num < 1000000) return (num / 1000).toFixed(2) + "k"; // Less than a million
    if (num < 1000000000) return (num / 1000000).toFixed(2) + "m"; // Less than a billion
    if (num < 1000000000000) return (num / 1000000000).toFixed(2) + "b"; // Less than a trillion
    return (num / 1000000000000).toFixed(2) + "t"; // Trillion or more
  }

  function formatPhoneNumber(phoneNumber) {
    // Remove non-digit characters
    const digits = phoneNumber.replace(/\D/g, "");

    // Check if the phone number has at least 10 digits
    if (digits.length >= 10) {
      // Format and return the phone number
      return `(${digits.substring(0, 3)}) ${digits.substring(3, 6)}-${digits.substring(6, 10)}`;
    } else {
      // Return the original string or a default message
      return phoneNumber; // Or return 'Invalid number';
    }
  }

  function formatBirthday(birthdayStr) {
    const birthday = new Date(birthdayStr);
    const formattedBirthday = (birthday.getMonth() + 1 + "").padStart(2, "0") + "/" + (birthday.getDate() + "").padStart(2, "0") + "/" + birthday.getFullYear().toString().substr(-2);
    return formattedBirthday;
  }

  const profile = user.userProfile;

  return (
    <>
      <Grid container sx={{ display: "flex", flexDirection: "column", width: "fit-content", gap: "16px" }}>
        {/* Name */}
        <Box sx={{ display: "flex", alignItems: "center", gap: "16px", flexDirection: isMobile ? "column" : "row" }}>
          <Avatar sx={{ height: "150px", width: "150px" }}>
            {profile.firstName[0]} {profile.lastName[0]}
          </Avatar>
          <Box>
            <Typography variant="body2">
              {profile.city}, {profile.state}
            </Typography>
            <Typography variant="h4" sx={{ fontWeight: "500" }}>
              {profile.firstName} {profile.lastName}
            </Typography>
            <Typography variant="body1" sx={{ color: "gray", maxWidth: "400px" }}>
              {profile.description}
            </Typography>
          </Box>
        </Box>

        <Box>
          <Box sx={{ display: "flex", alginItems: "center", justifyContent: "space-between", gap: "16px" }}>
            {/* Instagram */}
            <Box sx={{ p: 1, borderRadius: "8px", display: "flex", flexDirection: "column", alignItems: "start", backgroundColor: "#F1AEB5", width: "100%" }}>
              <IconButton sx={{ color: "white", backgroundColor: "#DC3545" }}>
                <Instagram style={{ width: "28px", height: "28px" }} />
              </IconButton>
              <Typography variant="h6" sx={{ lineHeight: "95%", mt: 1 }}>
                {formatNumber(profile.socials?.instagram.followers)}
              </Typography>
              <Typography variant="body2" sx={{ color: "black" }}>
                {profile.socials?.instagram.username}
              </Typography>
            </Box>
            {/* Twitter */}
            <Box sx={{ p: 1, borderRadius: "8px", display: "flex", flexDirection: "column", alignItems: "start", backgroundColor: "#FFE69C", width: "100%" }}>
              <IconButton sx={{ color: "white", backgroundColor: "#FFC107" }}>
                <TwitterX style={{ width: "28px", height: "28px" }} />
              </IconButton>
              <Typography variant="h6" sx={{ lineHeight: "95%", mt: 1 }}>
                {formatNumber(profile.socials?.twitter.followers)}
              </Typography>
              <Typography variant="body2">{profile.socials?.twitter.username}</Typography>
            </Box>
            {/* TikTok */}
            <Box sx={{ p: 1, borderRadius: "8px", display: "flex", flexDirection: "column", alignItems: "start", backgroundColor: "#AFE1C0", width: "100%" }}>
              <IconButton sx={{ color: "white", backgroundColor: "#6EBA67" }}>
                <Tiktok style={{ width: "28px", height: "28px" }} />
              </IconButton>
              <Typography variant="h6" sx={{ lineHeight: "95%", mt: 1 }}>
                {formatNumber(profile.socials?.tiktok.followers)}
              </Typography>
              <Typography variant="body2">{profile.socials?.tiktok.username}</Typography>
            </Box>
            {/* LinkedIn */}
            <Box sx={{ p: 1, borderRadius: "8px", display: "flex", flexDirection: "column", alignItems: "start", backgroundColor: "#9EC5FE", width: "100%" }}>
              <IconButton sx={{ color: "white", backgroundColor: "#0077B5" }}>
                <Linkedin style={{ width: "28px", height: "28px" }} />
              </IconButton>
              <Typography variant="h6" sx={{ lineHeight: "95%", mt: 1 }}>
                {formatNumber(profile.socials?.linkedin.followers)}
              </Typography>
              <Typography variant="body2">{profile.socials?.linkedin.username}</Typography>
            </Box>
          </Box>
        </Box>

        <Box>
          <Typography variant="h5" sx={{ mb: 1 }}>
            Campaign Budgets
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
            {/* Campaign A */}
            <Box sx={{ p: 1, borderRadius: "8px", display: "flex", flexDirection: "column", alignItems: "start", backgroundColor: "#EEEEEE" }}>
              <IconButton sx={{ color: "black", backgroundColor: "white" }}>
                <Typography variant="h6" sx={{ width: "28px", height: "28px" }}>
                  A
                </Typography>
              </IconButton>
              <Typography variant="h6" sx={{ lineHeight: "95%", mt: 1 }}>
                ${profile?.budgets?.campaignA}
              </Typography>
              <Typography variant="body2" sx={{ color: "black" }}>
                Campaign A
              </Typography>
            </Box>
            {/* Campaign B */}
            <Box sx={{ p: 1, borderRadius: "8px", display: "flex", flexDirection: "column", alignItems: "start", backgroundColor: "#EEEEEE" }}>
              <IconButton sx={{ color: "black", backgroundColor: "white" }}>
                <Typography variant="h6" sx={{ width: "28px", height: "28px" }}>
                  B
                </Typography>
              </IconButton>
              <Typography variant="h6" sx={{ lineHeight: "95%", mt: 1 }}>
                ${profile?.budgets?.campaignB}
              </Typography>
              <Typography variant="body2" sx={{ color: "black" }}>
                Campaign B
              </Typography>
            </Box>
            {/* Campaign C */}
            <Box sx={{ p: 1, borderRadius: "8px", display: "flex", flexDirection: "column", alignItems: "start", backgroundColor: "#EEEEEE" }}>
              <IconButton sx={{ color: "black", backgroundColor: "white" }}>
                <Typography variant="h6" sx={{ width: "28px", height: "28px" }}>
                  C
                </Typography>
              </IconButton>
              <Typography variant="h6" sx={{ lineHeight: "95%", mt: 1 }}>
                ${profile?.budgets?.campaignC}
              </Typography>
              <Typography variant="body2" sx={{ color: "black" }}>
                Campaign C
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
          <Typography variant="h5">About</Typography>
          <Box sx={{ display: "flex", gap: 2 }}>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <Box sx={{ backgroundColor: "#EEEEEE", display: "flex", alignItems: "center", justifyContent: "center", height: "36px", width: "36px", borderRadius: "100%" }}>
                  <Calendar style={{ width: "20px", height: "20px" }} />
                </Box>
                <Typography variant="body1" sx={{ color: "gray" }}>
                  {user.appliedOn ? <>Applied on {format(user.appliedOn, "MM/dd/yy")}</> : <>Accepted on {format(user.acceptedOn, "MM/dd/yy")}</>}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <Box sx={{ backgroundColor: "#EEEEEE", display: "flex", alignItems: "center", justifyContent: "center", height: "36px", width: "36px", borderRadius: "100%" }}>
                  <Cake style={{ width: "20px", height: "20px" }} />
                </Box>
                <Typography variant="body1" sx={{ color: "gray" }}>
                  {formatBirthday(profile.birthday)}
                </Typography>
              </Box>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <Box sx={{ backgroundColor: "#EEEEEE", display: "flex", alignItems: "center", justifyContent: "center", height: "36px", width: "36px", borderRadius: "100%" }}>
                  <Envelope style={{ width: "20px", height: "20px" }} />
                </Box>
                <Typography variant="body1" sx={{ color: "gray" }}>
                  {profile.email}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <Box sx={{ backgroundColor: "#EEEEEE", display: "flex", alignItems: "center", justifyContent: "center", height: "36px", width: "36px", borderRadius: "100%" }}>
                  <Phone style={{ width: "20px", height: "20px" }} />
                </Box>
                <Typography variant="body1" sx={{ color: "gray" }}>
                  {formatPhoneNumber(profile.phoneNumber)}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box sx={{ mb: 1 }}>
          <Typography variant="h5" sx={{ mb: 1 }}>
            Interests
          </Typography>
          <Box sx={{ display: "flex", gap: 1 }}>
            {profile.interests?.map((interest, index) => (
              <Chip label={interest} variant="body2" key={index}></Chip>
            ))}
          </Box>
        </Box>

        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <Button variant="outlined" onClick={() => handleProfileClose(user.userId)}>
            Close
          </Button>
          <Button sx={{ flexGrow: 1 }} endIcon={<Send />} variant="contained">
            Message
          </Button>
        </Box>
      </Grid>
    </>
  );
};

export default AccountSocialCard;
