import React from "react";
import { Box, Divider, Typography, Button } from "@mui/material";
import PieGraph from "./PieGraph";
import ModalCard from "../ModalCard";
import { useMediaQuery } from "react-responsive";

const InterestsBox = ({ applicants, acceptedUsers }) => {
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);
  const isMobile = useMediaQuery({ query: "(max-width: 931px)" });

  // Combine and flatten the interests from both applicants and acceptedUsers
  const combinedInterests = [
    ...(applicants?.map((applicant) => applicant.userProfile?.interests ?? []) ?? []).flat(),
    ...(acceptedUsers?.map((acceptedUser) => acceptedUser.userProfile?.interests ?? []) ?? []).flat(),
  ];

  // Initialize an empty object for counting interests
  const allInterestsCount = {};

  // Iterate over the combined interests array to count each interest
  combinedInterests.forEach((interest) => {
    if (interest) {
      // Check if interest is not undefined or null
      allInterestsCount[interest] = (allInterestsCount[interest] || 0) + 1;
    }
  });

  // Sort the interests by count and slice to get the top 5
  const topInterests = Object.entries(allInterestsCount)
    .sort((a, b) => b[1] - a[1])
    .slice(0, 5);

  // Convert back to an object
  const topInterestsCount = Object.fromEntries(topInterests);

  return (
    <>
      {isMobile ? <Divider /> : null}
      <Box sx={{ position: "relative", height: "400px", minWidth: "400px" }}>
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: 3, p: "1rem" }}>
          <Typography variant="body1" sx={{ lineHeight: "95%" }}>
            Interests
          </Typography>
          <Button variant="secondary" sx={{ color: "#2A78B5" }} onClick={() => setOpen(true)}>
            See All
          </Button>
        </Box>
        <Divider />
        <Box sx={{ height: "300px", width: "100%", position: "absolute", p: "1rem" }}>
          <PieGraph topInterestsCount={topInterestsCount} />
        </Box>
      </Box>
      <ModalCard open={open} handleClose={handleClose}>
        <Typography variant="h6">Interests</Typography>
        <Box sx={{ position: "relative", height: "500px", width: "500px" }}>
          <Box sx={{ height: "100%", width: "100%" }}>
            <PieGraph topInterestsCount={allInterestsCount} />
          </Box>
        </Box>
        <Button onClick={handleClose}>Close</Button>
      </ModalCard>
    </>
  );
};

export default InterestsBox;
