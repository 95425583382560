import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "white",
  p: 2,
  borderRadius: "1rem",
};

export default function ModalCard({ open, children, width }) {
  return (
    <div>
      <Modal open={open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box width={width} sx={style}>{children}</Box>
      </Modal>
    </div>
  );
}
