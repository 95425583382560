import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { AuthProvider } from "./contexts/AuthContext";
import HomePage from "./pages/Home";
// import LoginPage from "./pages/Login";
// import SignupPage from "./pages/Signup";
import AboutPage from "./pages/About";
import PrivateOutlet from "./components/PrivateOutlet";
// import AccountPage from "./pages/Account";
// import EditAccountPage from "./pages/Account/Edit";
// import Campaigns from "./pages/Campaigns";
// import CreateCampaigns from "./pages/Campaigns/Create";
// import EditCampaign from "./pages/Campaigns/Edit";
// import CampaignDashboard from "./pages/Campaigns/Dashboard";
import Waitlist from "./pages/Waitlist";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import { ToastContainer } from "react-toastify";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import theme from "./utils/theme";
import { ThemeProvider } from "@mui/material";
import "./assets/styles/App.css";
import { Container } from "@mui/material";

import AdminLogin from "./pages/AdminLogin";
import AdminDashboard from "./pages/AdminDashboard";
import AdminOutlet from "./components/AdminOutlet";
import PrivacyPage from "./pages/PrivacyPage";
import Contact from "./pages/Contact";
import Terms from "./pages/Terms";

const queryClient = new QueryClient();

function App() {
  return (
    <>
      <ThemeProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <AuthProvider>
            <Container sx={{ display: "flex", flexDirection: "column", minHeight: "100vh", overflow: "hidden" }}>
              <Navbar />
              <ToastContainer />
              <Router>
                <Routes>
                  <Route path="/" element={<HomePage />} />
                  <Route path="/waitlist" element={<Waitlist />} />
                  {/* <Route path="/login" element={<LoginPage />} />
                  <Route path="/signup" element={<SignupPage />} /> */}
                  <Route path="/about" element={<AboutPage />} /> 
                  <Route path="/privacy" element={<PrivacyPage />} />
                  <Route path="/contact" element={<Contact />} />
                  <Route path="/terms" element={<Terms />} />

                  <Route path="/sd978fbh39sdfh3o2092bdf89sdbf39b" element={<AdminLogin/>}/>
                  <Route element={<AdminOutlet/>}>
                    <Route path="/dashboard" element={<AdminDashboard/>}/>
                  </Route>

                  <Route element={<PrivateOutlet />}>
                    {/* <Route path="/account" element={<AccountPage />} />
                    <Route path="/account/edit" element={<EditAccountPage />} />
                    <Route path="/campaigns" element={<Campaigns />} />
                    <Route path="/campaigns/create" element={<CreateCampaigns />} />
                    <Route path="/campaigns/dashboard/:companyId/:campaignId" element={<CampaignDashboard />} />
                    <Route path="/campaigns/edit/:companyId/:campaignId" element={<EditCampaign />} /> */}
                  </Route>
                </Routes>
              </Router>
              <Footer sx={{ marginTop: "auto" }} />
            </Container>
          </AuthProvider>
        </QueryClientProvider>
      </ThemeProvider>
    </>
  );
}

export default App;
