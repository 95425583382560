import React from "react";
// import { getUserAuth } from "../utils/firebase";
import { Typography, Button, Box } from "@mui/material";
import Logo from "../assets/svgs/logo";

const Navbar = () => {
  // const [isUserLoggedIn, setUserLoggedIn] = useState(false);

  // const auth = getUserAuth();

  // useEffect(() => {
  //   const unsubscribe = auth.onAuthStateChanged((user) => {
  //     setUserLoggedIn(!!user);
  //   });

  //   return () => unsubscribe();
  // }, [auth]);

  return (
    <>
      <Box sx={{ display: "flex", marginBottom: "2rem", py: "1rem", justifyContent: "space-between"  }}>
        <Typography variant="h6" >
          <Button
            color="inherit"
            href="/"
            sx={{
              "&:hover": {
                animation: "bounce 0.5s",
                "@keyframes bounce": {
                  "0%, 100%": { transform: "translateY(0)" },
                  "50%": { transform: "translateY(-5px)" },
                },
                backgroundColor: "transparent",
              },
            }}
            disableRipple>
            <Logo style={{ height: "36" }} />
          </Button>

          {/* {isUserLoggedIn && (
            <Button color="inherit" href="/campaigns" sx={{ fontSize: "1rem" }}>
              Campaigns
            </Button>
          )} */}
        </Typography>
        <Button
          color="inherit"
          href="/waitlist"
          sx={{
            fontSize: "1rem",
            "&:hover": {
              animation: "bounce 0.5s",
              "@keyframes bounce": {
                "0%, 100%": { transform: "translateY(0)" },
                "50%": { transform: "translateY(-5px)" },
              },
              backgroundColor: "transparent",
            },
          }}>
          Join the waitlist
        </Button>
        {/* {isUserLoggedIn ? (
          <Button color="inherit" href="/account" sx={{ fontSize: "1rem" }} disabled>
            Account
          </Button>
        ) : (
          <>
            <Button color="inherit" href="/login" sx={{ fontSize: "1rem" }} disabled>
              Login
            </Button>
          </>
        )} */}
      </Box>
    </>
  );
};

export default Navbar;
