import React from "react";
import { Box, IconButton, Typography, Button } from "@mui/material";
import Logo from "../assets/svgs/logo";
import { Linkedin, Tiktok, Instagram, TwitterX } from "react-bootstrap-icons";
const Footer = () => {
  return (
    <>
      <Box sx={{ padding: "1rem", color: "black", mt: "auto", borderTopLeftRadius: "8px", borderTopRightRadius: "8px" }}>
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", gap: 3 }}>
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", gap: 3 }}>
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", gap: 0.75 }}>
              <Logo style={{ height: "28px", width: "28px" }} />
              <Typography variant="h5">Reach</Typography>
            </Box>
            {/* <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-around", gap: 2 }}>
              <Button variant="body1">Home</Button>
              <Button variant="body1">Campaigns</Button>
              <Button variant="body1">Help</Button>
              <Button variant="body1">Privacy</Button>
            </Box> */}
          </Box>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
            <Typography variant="body1" sx={{ fontSize: "12px" }}>
              &copy; 2024 PartTime LLC. All rights Reserved
            </Typography>
            <Button
              href="/privacy"
              sx={{
                color: "black",
                fontSize: "10px",
                padding: "2px 4px",
                minWidth: "auto",
                textTransform: "none",
                "&:hover": {
                  backgroundColor: "transparent",
                  textDecoration: "underline",
                },
              }}>
              Privacy Policy
            </Button>
            <Button
              href="/terms"
              sx={{
                color: "black",
                fontSize: "10px",
                padding: "2px 4px",
                minWidth: "auto",
                textTransform: "none",
                "&:hover": {
                  backgroundColor: "transparent",
                  textDecoration: "underline",
                },
              }}>
              Terms and Conditions
            </Button>
            <Button
              href="/about"
              sx={{
                color: "black",
                fontSize: "10px",
                padding: "2px 4px",
                minWidth: "auto",
                textTransform: "none",
                "&:hover": {
                  backgroundColor: "transparent",
                  textDecoration: "underline",
                },
              }}>
              About
            </Button>
            <Button
              href="/contact"
              sx={{
                color: "black",
                fontSize: "10px",
                padding: "2px 4px",
                minWidth: "auto",
                textTransform: "none",
                "&:hover": {
                  backgroundColor: "transparent",
                  textDecoration: "underline",
                },
              }}>
              Contact Us
            </Button>
          </Box>
          <Box>
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", gap: 2 }}>
              <IconButton sx={{ color: "black" }} component="a" href="https://www.linkedin.com/company/reachsocialus/" target="_blank" rel="noopener noreferrer">
                <Linkedin />
              </IconButton>
              <IconButton sx={{ color: "black" }} component="a" href="https://www.instagram.com/reachsocial.us/" target="_blank" rel="noopener noreferrer">
                <Instagram />
              </IconButton>
              <IconButton sx={{ color: "black" }} disabled>
                <TwitterX />
              </IconButton>
              <IconButton sx={{ color: "black" }} disabled>
                <Tiktok />
              </IconButton>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Footer;
