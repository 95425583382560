import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { ResponsivePie } from "@nivo/pie";
import { Box, Typography } from "@mui/material";
ChartJS.register(ArcElement, Tooltip, Legend);

const PieGraph = ({ topInterestsCount }) => {
  if (!topInterestsCount) return null;

  const data = Object.entries(topInterestsCount).map(([key, value]) => ({
    id: key,
    label: key,
    value: value,
  }));

  const CustomTooltip = ({ datum }) => {
    return (
      <Box sx={{ background: "white", boxShadow: 1, p: 1, borderRadius: "4px" }}>
        <Typography variant="body1">{datum.value}</Typography>
        <Typography variant="body2">{datum.id}</Typography>
      </Box>
    );
  };

  return (
    <>
      <ResponsivePie
        data={data}
        tooltip={CustomTooltip}
        margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
        startAngle={-1}
        innerRadius={0.25}
        padAngle={1}
        cornerRadius={6}
        activeOuterRadiusOffset={8}
        borderWidth={1}
        borderColor={{
          from: "color",
          modifiers: [["darker", 0.2]],
        }}
        colors={{ scheme: 'blues' }}
        enableArcLabels={false}
        enableArcLinkLabels={false}
        arcLabelsSkipAngle={10}
      />
    </>
  );
};

export default PieGraph;
