import React, { useState } from "react";
import { Formik, Field, Form } from "formik";
import { TextField, Button, Typography, Box, RadioGroup, FormControlLabel, Radio } from "@mui/material";
import { db } from "../utils/firebase"; // Assuming you already have firebase set up
import { doc, getDoc, setDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";

const Waitlist = () => {
  const navigate = useNavigate();
  const [waitlistStatus, setWaitlistStatus] = useState("");

  const checkWaitlist = async (email) => {
    try {
      const docRef = doc(db, "waitlist", email);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        return true; // User is already on the waitlist
      }
      return false; // User is not on the waitlist
    } catch (error) {
      console.error("Error checking waitlist:", error);
      return false;
    }
  };

  const addToWaitlist = async (values) => {
    try {
      const waitlistEntry = {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,

        userType: values.userType, // Either "individual" or "company"
        company: values.company || null, // Add company name if provided
        timestamp: new Date(),
      };
      await setDoc(doc(db, "waitlist", values.email), waitlistEntry);
      setTimeout(() => {
        navigate("/");
      }, 1000);
    } catch (error) {
      console.error("Error adding to waitlist:", error);
    }
  };

  return (
    <Formik
      initialValues={{
        firstName: "",
        lastName: "",
        email: "",
        userType: "individual", // Default to individual
        company: "",
      }}
      validateOnChange={false}
      validateOnBlur={false}
      validate={(values) => {
        const errors = {};
        if (!values.email) {
          errors.email = "Required";
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
          errors.email = "Invalid email address";
        }
        if (!values.firstName) {
          errors.firstName = "Required";
        }
        if (!values.lastName) {
          errors.lastName = "Required";
        }

        return errors;
      }}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        setWaitlistStatus("");
        const isAlreadyOnWaitlist = await checkWaitlist(values.email);

        if (isAlreadyOnWaitlist) {
          setWaitlistStatus("You are already on the waitlist.");
        } else {
          await addToWaitlist(values);
          setWaitlistStatus("You've been added to the waitlist. Thank you!");
        }
        setSubmitting(false);
        resetForm();
      }}>
      {({ values, errors, handleSubmit, isSubmitting, handleChange, handleBlur }) => (
        <Form onSubmit={handleSubmit} style={{ textAlign: "center", maxWidth: "600px" }}>
          <Typography variant="h4" sx={{ mb: 2 }}>
            Join the Waitlist
          </Typography>

          <Box sx={{ mb: 2, display: "flex", gap: 2 }}>
            <Field
              as={TextField}
              name="firstName"
              label="First Name"
              fullWidth
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.firstName}
              error={!!errors.firstName}
              helperText={errors.firstName && errors.firstName}
              required
              sx={{ "& .MuiOutlinedInput-root": { borderRadius: "120px" } }}
            />
            <Field
              as={TextField}
              name="lastName"
              label="Last Name"
              fullWidth
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.lastName}
              error={!!errors.lastName}
              helperText={errors.lastName && errors.lastName}
              required
              sx={{ "& .MuiOutlinedInput-root": { borderRadius: "120px" } }}
            />
          </Box>

          <Box sx={{ mb: 2 }}>
            <Field
              as={TextField}
              name="email"
              label="Email"
              fullWidth
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              error={!!errors.email}
              helperText={errors.email && errors.email}
              required
              sx={{ "& .MuiOutlinedInput-root": { borderRadius: "120px" } }}
            />
          </Box>

          {/* Radio group for user type selection */}
          <Box sx={{ mb: 2 }}>
            <RadioGroup name="userType" value={values.userType} onChange={handleChange} row sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              <FormControlLabel value="individual" control={<Radio />} label="Individual" />
              <FormControlLabel value="company" control={<Radio />} label="Company" />
            </RadioGroup>
          </Box>

          {/* Company name field only shown if user selects "Company" */}
          {values.userType === "company" && (
            <Box sx={{ mb: 2 }}>
              <Field
                as={TextField}
                name="company"
                label="Company Name"
                fullWidth
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.company}
                error={!!errors.company}
                helperText={errors.company && errors.company}
                sx={{ "& .MuiOutlinedInput-root": { borderRadius: "8px" } }}
              />
            </Box>
          )}

          <Button
            type="submit"
            variant="contained"
            disabled={isSubmitting}
            fullWidth
            sx={{
              backgroundColor: "black",
              color: "white",
              "&:hover": {
                backgroundColor: "green",
              },
              transition: "background-color 0.3s ease",
              borderRadius: "120px",
            }}>
            Join
          </Button>

          {waitlistStatus && (
            <Typography sx={{ mt: 2 }} color="primary">
              {waitlistStatus}
            </Typography>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default Waitlist;
