import React from "react";

const logo = ({ style }) => {
  return (
    <>
      <svg style={style} xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 500 500" preserveAspectRatio="none">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M285.896 212.813C294.684 193.753 299.587 172.533 299.587 150.167C299.587 67.4467 232.528 0.388428 149.808 0.388428C67.0873 0.388428 0.0290527 67.4467 0.0290527 150.167C0.0290527 172.533 4.93119 193.753 13.7196 212.813C45.5486 178.292 94.6799 156.158 149.808 156.158C204.936 156.158 254.067 178.292 285.896 212.813Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M212.454 483.963C193.394 492.752 172.173 497.654 149.808 497.654C67.0872 497.654 0.0290203 430.595 0.0290318 347.875C0.0290434 265.154 67.0873 198.096 149.808 198.096C172.173 198.096 193.394 202.998 212.454 211.787C177.933 243.616 155.799 292.747 155.799 347.875C155.799 403.003 177.933 452.134 212.454 483.963Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M483.604 287.964C492.392 307.023 497.294 328.244 497.294 350.61C497.294 433.33 430.236 500.388 347.515 500.388C264.795 500.388 197.737 433.33 197.737 350.61C197.737 328.244 202.639 307.023 211.427 287.964C243.256 322.485 292.387 344.619 347.515 344.619C402.643 344.619 451.774 322.485 483.604 287.964Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M287.604 14.0794C306.664 5.29092 327.885 0.388778 350.25 0.388777C432.971 0.388773 500.029 67.447 500.029 150.168C500.029 232.888 432.971 299.946 350.25 299.946C327.885 299.946 306.664 295.044 287.604 286.256C322.125 254.427 344.259 205.295 344.259 150.168C344.259 95.0396 322.125 45.9084 287.604 14.0794Z"
          fill="black"
        />
      </svg>
    </>
  );
};

export default logo;
