import React, { useState, useEffect } from "react";
import { Box, Typography, Grid, Container, Button } from "@mui/material";
import { motion, AnimatePresence } from "framer-motion";
import styled from "@emotion/styled";
import PeopleIcon from "@mui/icons-material/People";
import CampaignIcon from "@mui/icons-material/Campaign";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import { keyframes } from "@emotion/react";
import { Card } from "@mui/material";
import FlagIcon from "@mui/icons-material/Flag";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import TuneIcon from "@mui/icons-material/Tune";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import CreateIcon from "@mui/icons-material/Create";
import SearchIcon from "@mui/icons-material/Search";
import LaunchIcon from "@mui/icons-material/Launch";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import VideocamIcon from "@mui/icons-material/Videocam";
import RateReviewIcon from "@mui/icons-material/RateReview";
import UpdateIcon from "@mui/icons-material/Update";

const subtleBounceAnimation = keyframes`
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-5px); }
`;

const SubtleBouncyComponent = styled(motion.div)`
  animation: ${subtleBounceAnimation} 3s infinite;
`;

const gradientAnimation = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;

const AnimatedGradientText = styled.span`
  background: linear-gradient(to right, #20c997, #1976d2, #20c997);
  background-size: 200% auto;
  color: #000;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: ${gradientAnimation} 3s ease infinite;
`;

const GlassCard = styled(Card)`
  background: #cde5f9;
  backdrop-filter: blur(10px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: translateY(-5px);
  }
`;

const Section = ({ title, children }) => (
  <Box sx={{ my: 8 }}>
    <Typography variant="h4" sx={{ fontWeight: "bold", mb: 4, textAlign: "center", color: "black" }}>
      {title}
    </Typography>
    {children}
  </Box>
);

const About = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const slides = [
    {
      title: "Our Mission",
      icon: <FlagIcon fontSize="large" color="primary" />,
      content: "We are on a mission to align incentives between products and the customers who love them. We aim to bring word-of-mouth concepts to social media at scale. ",
    },
    {
      title: "What is Reach?",
      icon: <LightbulbIcon fontSize="large" color="primary" />,
      content:
        "We are a marketing freelancing platform that pays individuals to post photos about their favorite products on social media. Our software allows companies of any size to recruit, track, and manage thousands of their most loyal customers, to post photos and videos of their favorite products to their personal social media accounts in exchange for small flat fees, and product discounts.",
    },
  ];

  const comingSoonSlides = [
    {
      title: "Coming Soon",
      icon: <UpdateIcon fontSize="large" color="primary" />,
      content:
        "Stay tuned to our social media pages for more details about how you can join our first campaigns. We will be paying individuals on our waitlist to post Instagram stories featuring our content very soon. More details to come ASAP, our social media pages are linked in the footer.",
    },
  ];

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
    }, 10000);
    return () => clearInterval(timer);
  }, [slides.length]);

  const stats = [
    { icon: <PeopleIcon sx={{ fontSize: 48, color: "#1976d2" }} />, value: "159", label: "Waitlist Members" },
    { icon: <CampaignIcon sx={{ fontSize: 48, color: "#20c997" }} />, value: "Launching soon", label: "Campaigns Run" },
    { icon: <MonetizationOnIcon sx={{ fontSize: 48, color: "#ff9800" }} />, value: "Launching soon", label: "Revenue Generated for Creators" },
  ];

  const howItWorksStepsIndividuals = [
    { icon: <PersonAddIcon fontSize="large" color="primary" />, title: "Create Profile", description: "Create an account, link your favorite social media accounts." },
    { icon: <TuneIcon fontSize="large" color="primary" />, title: "Optimize Profile", description: "Input your interests, top 5 favorite brands, and set your pricing preferences." },
    { icon: <GroupAddIcon fontSize="large" color="primary" />, title: "Join Campaign", description: "You can get invited to them, apply for them, or be eligible to automatically join them." },
    { icon: <AssignmentTurnedInIcon fontSize="large" color="primary" />, title: "Complete Tasks", description: "Complete campaign requirements, submit analytics, get paid." },
  ];

  const howItWorksStepsBrands = [
    { icon: <CreateIcon fontSize="large" color="primary" />, title: "Create Account", description: "Create account, identify brand interests, input ideal demographics." },
    { icon: <SearchIcon fontSize="large" color="primary" />, title: "Create Campaigns", description: "Set a budget and allow customers to auto-join, apply, or receive an invite to your campaigns." },
    { icon: <LaunchIcon fontSize="large" color="primary" />, title: "Deploy Campaigns", description: "Launch your campaign and watch as your customers start producing content." },
    { icon: <AnalyticsIcon fontSize="large" color="primary" />, title: "Track Results", description: "Monitor campaign performance and analytics. Each user submits their post's stats." },
  ];

  const campaignTypes = [
    {
      icon: <PhotoCameraIcon sx={{ fontSize: 48, color: "#1976d2" }} />,
      title: "Story Posts",
      description: "Share casual photos/videos of your favorite products on your Instagram or Snapchat stories.",
    },
    { icon: <RateReviewIcon sx={{ fontSize: 48, color: "#20c997" }} />, title: "Provided Content", description: "If you don't want to create your own content, brands can provide content to you." },
    { icon: <VideocamIcon sx={{ fontSize: 48, color: "#ff9800" }} />, title: "UGC Content", description: "Create honest reviews of your favorite products on your preferred platform." },
  ];

  return (
    <Container maxWidth="lg">
      <Box sx={{ py: 8 }}>
        <motion.div initial={{ opacity: 0, y: -50 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.5 }}>
          <Typography variant="h3" sx={{ fontWeight: "bold", textAlign: "center", mb: 4, color: "black" }}>
            <AnimatedGradientText>Reach Social</AnimatedGradientText>
          </Typography>
        </motion.div>

        <Section title="How It Works for Individuals:">
          <Grid container spacing={4} justifyContent="center">
            {howItWorksStepsIndividuals.map((step, index) => (
              <Grid item xs={12} sm={6} md={3} key={index}>
                <motion.div whileHover={{ scale: 1.05 }} transition={{ type: "spring", stiffness: 300 }}>
                  <SubtleBouncyComponent>
                    <GlassCard sx={{ height: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", p: 3 }}>
                      {step.icon}
                      <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2, mt: 2, color: "black" }}>
                        {step.title}
                      </Typography>
                      <Typography variant="body1" sx={{ color: "black", textAlign: "center" }}>
                        {step.description}
                      </Typography>
                    </GlassCard>
                  </SubtleBouncyComponent>
                </motion.div>
              </Grid>
            ))}
          </Grid>
        </Section>

        <Section title="How it Works for Brands:">
          <Grid container spacing={4} justifyContent="center">
            {howItWorksStepsBrands.map((step, index) => (
              <Grid item xs={12} sm={6} md={3} key={index}>
                <motion.div whileHover={{ scale: 1.05 }} transition={{ type: "spring", stiffness: 300 }}>
                  <SubtleBouncyComponent>
                    <GlassCard sx={{ height: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", p: 3 }}>
                      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "64px" }}>{step.icon}</Box>
                      <Typography variant="h6" sx={{ fontWeight: "bold", mb: 2, mt: 2, color: "black" }}>
                        {step.title}
                      </Typography>
                      <Typography variant="body1" sx={{ color: "black", textAlign: "center" }}>
                        {step.description}
                      </Typography>
                    </GlassCard>
                  </SubtleBouncyComponent>
                </motion.div>
              </Grid>
            ))}
          </Grid>
        </Section>

        <Section title="Our Mission & Purpose">
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              <motion.div whileHover={{ scale: 1.02 }} whileTap={{ scale: 0.98 }}>
                <SubtleBouncyComponent>
                  <GlassCard sx={{ p: 4, display: "flex", alignItems: "center", height: "100%", minHeight: "200px" }}>
                    <AnimatePresence mode="wait">
                      <motion.div
                        key={currentSlide}
                        initial={{ opacity: 0, x: 20 }}
                        animate={{ opacity: 1, x: 0 }}
                        exit={{ opacity: 0, x: -20 }}
                        transition={{ duration: 0.5 }}
                        style={{ width: "100%" }}>
                        <Box sx={{ display: "flex", alignItems: "flex-start", width: "100%" }}>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              backgroundColor: "white",
                              width: "64px",
                              height: "64px",
                              borderRadius: "50%",
                              mr: 3,
                              flexShrink: 0,
                            }}>
                            {slides[currentSlide].icon}
                          </Box>
                          <Box sx={{ flexGrow: 1 }}>
                            <Typography variant="h4" component="h2" sx={{ mb: 2, color: "black" }}>
                              {slides[currentSlide].title}
                            </Typography>
                            <Typography variant="body1" sx={{ color: "black", fontSize: "1.1rem", lineHeight: 1.6 }}>
                              {slides[currentSlide].content}
                            </Typography>
                          </Box>
                        </Box>
                      </motion.div>
                    </AnimatePresence>
                  </GlassCard>
                </SubtleBouncyComponent>
              </motion.div>
            </Grid>
          </Grid>
        </Section>

        <Section title="Campaign Types">
          <Grid container spacing={4} justifyContent="center">
            {campaignTypes.map((type, index) => (
              <Grid item xs={12} sm={4} key={index}>
                <motion.div whileHover={{ scale: 1.05 }} transition={{ type: "spring", stiffness: 300 }}>
                  <SubtleBouncyComponent>
                    <GlassCard sx={{ height: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", p: 3 }}>
                      {type.icon}
                      <Typography variant="h5" sx={{ fontWeight: "bold", my: 2, color: "black" }}>
                        {type.title}
                      </Typography>
                      <Typography variant="body1" sx={{ color: "black", textAlign: "center" }}>
                        {type.description}
                      </Typography>
                    </GlassCard>
                  </SubtleBouncyComponent>
                </motion.div>
              </Grid>
            ))}
          </Grid>
        </Section>

        <Section title="Coming Soon">
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              <motion.div whileHover={{ scale: 1.02 }} whileTap={{ scale: 0.98 }}>
                <SubtleBouncyComponent>
                  <GlassCard sx={{ p: 4, display: "flex", alignItems: "center", height: "100%", minHeight: "200px" }}>
                    <Box sx={{ display: "flex", alignItems: "flex-start", width: "100%" }}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          backgroundColor: "white",
                          width: "64px",
                          height: "64px",
                          borderRadius: "50%",
                          mr: 3,
                          flexShrink: 0,
                        }}>
                        {comingSoonSlides[0].icon}
                      </Box>
                      <Box sx={{ flexGrow: 1 }}>
                        <Typography variant="h4" component="h2" sx={{ mb: 2, color: "black" }}>
                          {comingSoonSlides[0].title}
                        </Typography>
                        <Typography variant="body1" sx={{ color: "black", fontSize: "1.1rem", lineHeight: 1.6 }}>
                          {comingSoonSlides[0].content}
                        </Typography>
                      </Box>
                    </Box>
                  </GlassCard>
                </SubtleBouncyComponent>
              </motion.div>
            </Grid>
          </Grid>
        </Section>

        <Section title="Our Impact">
          <Grid container spacing={4} justifyContent="center">
            {stats.map((stat, index) => (
              <Grid item xs={12} sm={4} key={index}>
                <motion.div whileHover={{ scale: 1.05 }} transition={{ type: "spring", stiffness: 300 }}>
                  <SubtleBouncyComponent>
                    <GlassCard sx={{ height: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", p: 3 }}>
                      {stat.icon}
                      <Typography variant="h4" sx={{ fontWeight: "bold", my: 2, color: "black" }}>
                        {stat.value}
                      </Typography>
                      <Typography variant="body1" sx={{ color: "black" }}>
                        {stat.label}
                      </Typography>
                    </GlassCard>
                  </SubtleBouncyComponent>
                </motion.div>
              </Grid>
            ))}
          </Grid>
        </Section>

        <Section title="Join Reach Today">
          <Box sx={{ textAlign: "center" }}>
            <Typography variant="h5" sx={{ mb: 3, color: "black" }}>
              Ready to upgrade your social media strategy?
            </Typography>
            <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
              <Button
                variant="contained"
                size="large"
                href="/waitlist"
                sx={{
                  bgcolor: "black",
                  color: "white",
                  "&:hover": {
                    bgcolor: "primary.main",
                  },
                  borderRadius: "50px",
                  px: 4,
                  py: 1.5,
                  fontSize: "1.1rem",
                  textTransform: "none",
                }}>
                Join Waitlist
              </Button>
            </motion.div>
          </Box>
        </Section>
      </Box>
    </Container>
  );
};

export default About;
