import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { ResponsiveLine } from "@nivo/line";
import { format } from "date-fns";
import { useMediaQuery } from "react-responsive";
const LineChart = ({ applicants, acceptedUsers }) => {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  const [data, setData] = useState([]);


  useEffect(() => {
    const processData = () => {
      const sortedApplicants = applicants.sort((a, b) => new Date(a.appliedOn) - new Date(b.appliedOn));
      const sortedAcceptedUsers = acceptedUsers.sort((a, b) => new Date(a.acceptedOn) - new Date(b.acceptedOn));

      const dateCounts = {};
      const allDates = new Set();

      // Count applicants per date
      sortedApplicants.forEach((applicant) => {
        const date = new Date(applicant.appliedOn);
        if (!dateCounts[date]) {
          dateCounts[date] = { applications: 0, acceptedUsers: 0 };
        }
        dateCounts[date].applications += 1;
        allDates.add(date);
      });

      // Count accepted users per date
      sortedAcceptedUsers.forEach((user) => {
        const date = new Date(user.acceptedOn);
        if (!dateCounts[date]) {
          dateCounts[date] = { applications: 0, acceptedUsers: 0 };
        }
        dateCounts[date].acceptedUsers += 1;
        allDates.add(date);
      });

      // Ensure every date has both applications and acceptedUsers counts
      const datesArray = Array.from(allDates).sort((a, b) => new Date(a) - new Date(b));
      const formattedData = [
        {
          id: "Applications",
          data: datesArray.map((date) => ({
            x: date,
            y: dateCounts[date] ? dateCounts[date].applications : 0,
          })),
        },
        {
          id: "Accepted Users",
          data: datesArray.map((date) => ({
            x: date,
            y: dateCounts[date] ? dateCounts[date].acceptedUsers : 0,
          })),
        },
      ];

      setData(formattedData);
    };

    processData();
  }, [applicants, acceptedUsers]);

  const CustomTooltip = ({ point }) => {
    return (
      <Box sx={{ background: "white", boxShadow: 1, p: 1, borderRadius: "4px" }}>
        <Typography variant="body1">
          {point.data.yFormatted} {point.serieId}
        </Typography>
        <Typography variant="body2">{format(new Date(point.data.xFormatted), "PPP")}</Typography>
      </Box>
    );
  };

  return (
    <Box height={400}>
      {data.length > 0 && data.some((d) => d.data.length > 0) ? (
        <ResponsiveLine
          data={data}
          colors={{ scheme: 'paired' }}
          margin={{ top: 30, right: isMobile ? 25 : 25, bottom: 30, left: isMobile ? 25 : 25 }}
          xScale={{ type: "point" }}
          yScale={{ type: "linear", min: 0, max: "auto" }}
          curve="catmullRom"
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: isMobile ? 30 : 0,
            format: (value) => format(new Date(value), "MM/dd"),  // Convert value to Date
          }}
          
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            truncateTickAt: 0,
          }}
          theme={{
            axis: {
              ticks: {
                text: {
                  fontSize: "10px", // Adjust the font size
                  fontFamily: "Poppins", // Change the font family
                  fill: "#333", // Set text color
                },
              },
            },
          }}
          gridYValues={5}
          
          enableArea={true}
          pointSize={8}
          pointColor={{ theme: "background" }}
          pointBorderWidth={2}
          pointBorderColor={{ from: "serieColor" }}
          enableCrosshair={true}
          enablePoints={true}
          useMesh={true}
          enableGridX={false}
          tooltip={CustomTooltip}
        />
      ) : (
        <Typography variant="body1" sx={{ textAlign: "center", mt: 2 }}>
          No data available to display.
        </Typography>
      )}
    </Box>
  );
};

export default LineChart;
