import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";

const AdminOutlet = ({ children }) => {
    const { currentUser } = useAuth();

    // Assuming your auth context provides a way to check if the user is an admin
    const isAdmin = currentUser && currentUser.uid === "S0DD49pFNpdeaFA2JpT3m42dDig1"; // replace "admin_uid" with the actual admin uid
  
    if (isAdmin) {
      return <Outlet />;
    }
  
    // If not authenticated or not an admin, redirect to login page
    return <Navigate to="/" replace />;
};

export default AdminOutlet;
