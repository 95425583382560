import React from 'react';
import { Box, Typography, Link } from '@mui/material';

const Contact = () => {
  return (
    <Box sx={{ 
      display: 'flex', 
      flexDirection: 'column', 
      alignItems: "center", 
      justifyContent: "center",
      
    }}>
      <Typography variant="h4" component="h1" gutterBottom>
        Contact Us
      </Typography>
      <Typography variant="body1">
        For any inquiries, please contact{' '}
        <Link href="mailto:peter@reachsocial.app">
          peter@reachsocial.app
        </Link>
      </Typography>
    </Box>
  );
};

export default Contact;
