import React, { useState, useEffect } from "react";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../utils/firebase";

const AdminDashboard = () => {
  const [tiktokFollowers, setTiktokFollowers] = useState(0);
  const [tiktokBudget, setTiktokBudget] = useState(0);
  const [linkedinFollowers, setLinkedinFollowers] = useState(0);
  const [linkedinBudget, setLinkedinBudget] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch TikTok data
        const tiktokDoc = await getDoc(doc(db, "homedata", "tiktok"));
        if (tiktokDoc.exists()) {
          setTiktokFollowers(tiktokDoc.data().followers);
          setTiktokBudget(tiktokDoc.data().budget);
        }

        // Fetch LinkedIn data
        const linkedinDoc = await getDoc(doc(db, "homedata", "linkedin"));
        if (linkedinDoc.exists()) {
          setLinkedinFollowers(linkedinDoc.data().followers);
          setLinkedinBudget(linkedinDoc.data().budget);
        }
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    fetchData();
  }, []);

  const updateTiktokData = async (e) => {
    e.preventDefault();

    try {
      const docRef = doc(db, "homedata", "tiktok");
      await updateDoc(docRef, {
        followers: parseInt(e.target.tiktokFollowers.value),
        budget: parseInt(e.target.tiktokBudget.value),
      });
      alert("Tiktok data updated successfully");
    } catch (error) {
      console.error("Error updating Tiktok data: ", error);
    }
  };

  const updateLinkedinData = async (e) => {
    e.preventDefault();

    try {
      const docRef = doc(db, "homedata", "linkedin");
      await updateDoc(docRef, {
        followers: parseInt(e.target.linkedinFollowers.value),
        budget: parseInt(e.target.linkedinBudget.value),
      });
      alert("Linkedin data updated successfully");
    } catch (error) {
      console.error("Error updating Linkedin data: ", error);
    }
  };

  return (
    <>
      <h1>Admin Dashboard</h1>

      <form onSubmit={updateTiktokData}>
        <p>Tiktok Followers: {tiktokFollowers}</p>
        <input
          type="number"
          id="tiktokFollowers"
          name="tiktokFollowers"
          
          defaultValue={tiktokFollowers}
          required
        />
        <p>Tiktok Budget: {tiktokBudget}</p>
        <input
          type="number"
          id="tiktokBudget"
          name="tiktokBudget"
          defaultValue={tiktokBudget}
          required
        />
        <button type="submit">Update Tiktok</button>
      </form>

      <hr />

      <form onSubmit={updateLinkedinData}>
        <p>Linkedin Followers: {linkedinFollowers}</p>
        <input
          type="number"
          id="linkedinFollowers"
          name="linkedinFollowers"
          defaultValue={linkedinFollowers}
          required
        />
        <p>Linkedin Budget: {linkedinBudget}</p>
        <input
          type="number"
          id="linkedinBudget"
          name="linkedinBudget"
          defaultValue={linkedinBudget}
          required
        />
        <button type="submit">Update Linkedin</button>
      </form>
    </>
  );
};

export default AdminDashboard;
