import React from "react";
import { Avatar, Box, Button, Divider, Typography, TableContainer, Table, TableHead, TableCell, TableRow, TableBody, ButtonGroup, TablePagination } from "@mui/material";
import { formatDistance, format } from "date-fns";
import ModalCard from "../ModalCard";
import AccountSocialCard from "./AccountSocialCard";
import { useMediaQuery } from "react-responsive";

const AcceptedBox = ({ acceptedUsers }) => {
  const isMobile = useMediaQuery({ query: "(max-width: 514px)" });

  const [open, setOpen] = React.useState(false);
  const [profileOpen, setProfileOpen] = React.useState({});

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleClose = () => setOpen(false);

  const handleProfileOpen = (userId) => setProfileOpen((prev) => ({ ...prev, [userId]: true }));
  const handleProfileClose = (userId) => {
    setProfileOpen((prev) => ({ ...prev, [userId]: false }));
  };

  function calculateAge(birthdayStr) {
    const birthday = new Date(birthdayStr);
    const today = new Date();
    let age = today.getFullYear() - birthday.getFullYear();
    const m = today.getMonth() - birthday.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthday.getDate())) {
      age--;
    }
    return age;
  }

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  return (
    <>
      {isMobile ? <Divider /> : null}
      <Box>
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: 3, p: "1rem" }}>
          <Typography variant="body1" sx={{ lineHeight: "95%" }}>
            Accepted Users
          </Typography>
          <Button variant="secondary" sx={{ color: "#2A78B5" }} onClick={() => setOpen(true)}>
            See All
          </Button>
        </Box>
        <Divider />
        <Box sx={{ display: "flex", flexDirection: "column", p: "1rem", justifyContent: "space-around", gap: 2.5 }}>
          {acceptedUsers
            ?.sort((a, b) => new Date(b.acceptedOn) - new Date(a.acceptedOn)) // Sort applicants by appliedOn date in descending order
            ?.slice(0, 4) // Get only the first three elements
            ?.map((acceptedUser) => (
              <Box key={acceptedUser.userId} sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <Avatar>
                  <Avatar>{acceptedUser.userProfile.firstName[0] + acceptedUser.userProfile.lastName[0]}</Avatar>
                </Avatar>
                <Box sx={{ textAlign: "right" }}>
                  <Typography variant="body1" sx={{ lineHeight: "95%" }}>
                    {acceptedUser.userProfile.firstName} {acceptedUser.userProfile.lastName}
                  </Typography>
                  <Typography variant="body2" sx={{ color: "gray" }}>
                    {formatDistance(new Date(acceptedUser?.acceptedOn), new Date()) + " ago"}
                  </Typography>
                </Box>
              </Box>
            ))}
        </Box>
      </Box>

      {/* Modal */}
      <ModalCard open={open} handleClose={handleClose} width={{ width: "90%" }}>
        <Typography variant="h6">Accepted Users</Typography>

        <Box sx={{ overflowX: "auto" }}>
          <TableContainer sx={{ maxHeight: 500 }}>
            <Table stickyHeader sx={{ minWidth: 650 }} aria-label="dashboard-table">
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell align="left">First</TableCell>
                  <TableCell align="left">Last</TableCell>
                  <TableCell align="left">Age</TableCell>
                  <TableCell align="left">
                    <Box sx={{ width: "70px" }}>Budget A</Box>
                  </TableCell>
                  <TableCell align="left">
                    <Box sx={{ width: "70px" }}>Budget B</Box>
                  </TableCell>
                  <TableCell align="left">
                    <Box sx={{ width: "70px" }}>Budget C</Box>
                  </TableCell>
                  <TableCell align="left">Accepted</TableCell>
                  <TableCell align="left">City</TableCell>
                  <TableCell align="left">State</TableCell>

                  <TableCell align="right">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {acceptedUsers
                  ?.sort((a, b) => new Date(b.acceptedOn) - new Date(a.acceptedOn))
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  ?.map((acceptedUser) => (
                    <TableRow key={acceptedUser.userId}>
                      {/* Avatar */}
                      <TableCell scope="row">
                        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                          {acceptedUser.userProfile.profileImage ? (
                            <Avatar sx={{}} src={acceptedUser.userProfile.profileImage} />
                          ) : (
                            <Avatar>{acceptedUser.userProfile.firstName[0] + acceptedUser.userProfile.lastName[0]}</Avatar>
                          )}
                        </Box>
                      </TableCell>
                      {/* First Name */}
                      <TableCell scope="row">
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                          <Typography variant="body1">{acceptedUser.userProfile.firstName}</Typography>
                        </Box>
                      </TableCell>
                      {/* Last Name */}
                      <TableCell scope="row">
                        <Typography variant="body1">{acceptedUser.userProfile.lastName}</Typography>
                      </TableCell>
                      {/* Age */}
                      <TableCell scope="row">
                        <Typography variant="body1">{calculateAge(acceptedUser.userProfile.birthday)}</Typography>
                      </TableCell>
                      {/* Budget A */}
                      <TableCell scope="row">
                        <Typography variant="body1">{formatter.format(acceptedUser?.userProfile?.budgets?.campaignA)}</Typography>
                      </TableCell>
                      {/* Budget B */}
                      <TableCell scope="row">
                        <Typography variant="body1">{formatter.format(acceptedUser?.userProfile?.budgets?.campaignB)}</Typography>
                      </TableCell>
                      {/* Budget C */}
                      <TableCell scope="row">
                        <Typography variant="body1">{formatter.format(acceptedUser?.userProfile?.budgets?.campaignC)}</Typography>
                      </TableCell>
                      {/* Date Applied */}
                      <TableCell scope="row">
                        <Typography variant="body1">{format(acceptedUser.acceptedOn, "MM/dd/yy")}</Typography>
                      </TableCell>
                      {/* City */}
                      <TableCell scope="row">
                        <Typography variant="body1">{acceptedUser.userProfile.city}</Typography>
                      </TableCell>
                      {/* State */}
                      <TableCell scope="row">
                        <Typography variant="body1">{acceptedUser.userProfile.state}</Typography>
                      </TableCell>
                      <TableCell align="right">
                        <ButtonGroup>
                          <Button onClick={() => handleProfileOpen(acceptedUser.userId)} variant="outlined">
                            View
                          </Button>

                          <Button variant="outlined" color="error">
                            Remove
                          </Button>
                        </ButtonGroup>
                      </TableCell>
                      <ModalCard open={!!profileOpen[acceptedUser.userId]} handleClose={() => handleProfileClose(acceptedUser.userId)}>
                        <AccountSocialCard handleProfileClose={handleProfileClose} user={acceptedUser} />
                      </ModalCard>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={acceptedUsers.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>

        <Button variant="outlined" onClick={handleClose} sx={{ mt: 1 }}>
          Close
        </Button>
      </ModalCard>
    </>
  );
};

export default AcceptedBox;
